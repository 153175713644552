import { localStorageApi } from 'api/localStorage';
import { DEFAULT_LOCALE, Locale } from 'intl/translations';
import { SgBootstrapTheme } from 'theme';

export type TimeZoneType = 'local' | 'exchange';

export interface SessionsState {
  isExpired: boolean;
  locale: Locale;
  theme: SgBootstrapTheme | undefined;
  timeZoneType: TimeZoneType;
}

export const initialSessionsState: SessionsState = {
  isExpired: false,
  locale: DEFAULT_LOCALE,
  theme: undefined,
  timeZoneType: localStorageApi.get<TimeZoneType>('APP_TIMEZONE_TYPE') ?? 'exchange',
};
