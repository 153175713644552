import { map, Observable } from 'rxjs';
import { getDataQueryString } from './helpers/queryParams.helpers';
import { Position } from 'models/Position';
import { QueryFilters } from 'models/QueryFilters';
import { DataResponse } from 'models/Responses/DataResponse';
import { HttpClient } from 'utils/http/httpClient.models';

export interface PositionsApi {
  query(exchange: string, filters: QueryFilters): Observable<DataResponse<Position>>;
}

export const positionsApiConnector = (httpClient: HttpClient): PositionsApi => ({
  query(exchange: string, filters: QueryFilters): Observable<DataResponse<Position>> {
    const qs = getDataQueryString(filters);
    return httpClient
      .getJSON<DataResponse<Position>>({ url: `/api/v1/${exchange}/positions?${qs}` })
      .pipe(map(({ payload }) => payload));
  },
});
