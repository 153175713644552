import { MatomoEventActions, MatomoEventCategory } from 'models/Matomo';
import { User } from 'models/User';

const addMatomoScript = (matomoScriptUrl: string): void => {
  const firstScript = document.getElementsByTagName('script')[0];
  const matomoScript = document.createElement('script');

  matomoScript.type = 'text/javascript';
  matomoScript.async = true;
  matomoScript.defer = true;
  matomoScript.src = matomoScriptUrl;

  if (firstScript === null || firstScript.parentNode === null)
    throw new Error('Can not inject piwik source code into document');

  firstScript.parentNode.insertBefore(matomoScript, firstScript);
};

export const initializeMatomo = (): void => {
  window._paq = window._paq ?? [];

  if (!window.appConfigs.matomo) return;

  const { base, siteId } = window.appConfigs.matomo;
  const matomoScriptUrl = `${base}piwik.js`;
  const matomoTrackerUrl = `${base}piwik.php`;

  window._paq.push(['requireCookieConsent']);
  window._paq.push(['trackPageView']);
  window._paq.push(['enableLinkTracking']);
  window._paq.push(['setTrackerUrl', matomoTrackerUrl]);
  window._paq.push(['setSiteId', siteId]);

  addMatomoScript(matomoScriptUrl);
};

export const trackUserInMatomo = (user: User): void => {
  window._paq.push(['setUserId', user.email]);
  window._paq.push(['setCustomVariable', 1, 'user-type', user.isSgGroupUser ? 'Internal' : 'External', 'visit']);
  if (user.isSgGroupUser) {
    if (user.department) window._paq.push(['setCustomVariable', 2, 'user-department', user.department, 'visit']);
    window._paq.push(['setCustomVariable', 3, 'connection-network', user.connectionNetwork, 'visit']);
  }
};

export const trackMatomoEvent = <C extends MatomoEventCategory>(
  category: C,
  action: MatomoEventActions[C],
  name?: string,
  value?: unknown,
): void => {
  let data = ['trackEvent', category, action];

  if (name) data = [...data, name];

  if (value) {
    let formattedValue: string | undefined;

    if (value instanceof Date) {
      formattedValue = value.toISOString();
    } else if (value === null) {
      formattedValue = undefined;
    } else {
      switch (typeof value) {
        case 'object':
          formattedValue = JSON.stringify(value);
          break;
        case 'number':
        case 'bigint':
        case 'symbol':
          formattedValue = value.toString();
          break;
        case 'boolean':
          formattedValue = value ? 'true' : 'false';
          break;
        case 'string':
          formattedValue = value;
          break;
        case 'function':
        default:
          formattedValue = undefined;
      }
    }

    if (formattedValue) data = [...data, formattedValue];
  }

  window._paq.push(data);
};
