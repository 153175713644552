import { FiltersState } from './filters.model';
import { FilterOption } from 'models/FilterOption';
import { DcErrorResponse } from 'models/Responses/DcErrorResponse';
import { RootState } from 'store/state';

// TODO: unit tests

const selectFiltersState = (state: RootState): FiltersState => state.filters;

export const getCustomerFilterOptions =
  (exchange?: string) =>
  (state: RootState): FilterOption[] => {
    if (!exchange) return [];
    return selectFiltersState(state).customers?.[exchange]?.options ?? [];
  };

export const getCustomerFilterOptionsFetchError =
  (exchange?: string) =>
  (state: RootState): DcErrorResponse | undefined =>
    exchange ? selectFiltersState(state).customers?.[exchange]?.error : undefined;

export const isCustomerFilterOptionsFetched =
  (exchange: string) =>
  (state: RootState): boolean =>
    exchange in selectFiltersState(state).customers;

export const getProductFilterOptions =
  (exchange?: string) =>
  (state: RootState): FilterOption[] => {
    if (!exchange) return [];
    return selectFiltersState(state).products?.[exchange]?.options ?? [];
  };

export const getProductFilterOptionsFetchError =
  (exchange?: string) =>
  (state: RootState): DcErrorResponse | undefined =>
    exchange ? selectFiltersState(state).products?.[exchange]?.error : undefined;

export const isProductFilterOptionsFetched =
  (exchange: string) =>
  (state: RootState): boolean =>
    exchange in selectFiltersState(state).products;

export const getTradingSystemFilterOptions =
  (exchange?: string, customer?: string) =>
  (state: RootState): FilterOption[] => {
    if (!exchange || !customer) return [];
    return selectFiltersState(state).tradingSystems?.[exchange]?.[customer]?.options ?? [];
  };

export const getTradingSystemFilterOptionsFetchError =
  (exchange?: string, customer?: string) =>
  (state: RootState): DcErrorResponse | undefined =>
    exchange && customer ? selectFiltersState(state).tradingSystems?.[exchange]?.[customer]?.error : undefined;

export const isTradingSystemFilterOptionsFetched =
  (exchange: string, customer: string) =>
  (state: RootState): boolean =>
    exchange in selectFiltersState(state).tradingSystems &&
    customer in selectFiltersState(state).tradingSystems?.[exchange];

export const getCustomerSystemFilterOptions =
  (exchange?: string, customer?: string, tradingSystem?: string) =>
  (state: RootState): FilterOption[] => {
    if (!exchange || !customer || !tradingSystem) return [];
    return selectFiltersState(state).customerSystems?.[exchange]?.[customer]?.[tradingSystem]?.options ?? [];
  };

export const getCustomerSystemFilterOptionsFetchError =
  (exchange?: string, customer?: string, tradingSystem?: string) =>
  (state: RootState): DcErrorResponse | undefined =>
    exchange && customer && tradingSystem
      ? selectFiltersState(state).customerSystems?.[exchange]?.[customer]?.[tradingSystem]?.error
      : undefined;

export const isCustomerSystemFilterOptionsFetched =
  (exchange: string, customer: string, tradingSystem: string) =>
  (state: RootState): boolean =>
    exchange in selectFiltersState(state).customerSystems &&
    customer in selectFiltersState(state).customerSystems?.[exchange] &&
    tradingSystem in selectFiltersState(state).customerSystems?.[exchange]?.[customer];

export const getUserSystemFilterOptions =
  (exchange?: string, customer?: string, tradingSystem?: string, customerSystemId?: string) =>
  (state: RootState): FilterOption[] => {
    if (!exchange || !customer || !tradingSystem || !customerSystemId) return [];
    return (
      selectFiltersState(state).userSystems?.[exchange]?.[customer]?.[tradingSystem]?.[customerSystemId]?.options ?? []
    );
  };

export const getUserSystemFilterOptionsFetchError =
  (exchange?: string, customer?: string, tradingSystem?: string, customerSystemId?: string) =>
  (state: RootState): DcErrorResponse | undefined =>
    exchange && customer && tradingSystem && customerSystemId
      ? selectFiltersState(state).userSystems?.[exchange]?.[customer]?.[tradingSystem]?.[customerSystemId]?.error
      : undefined;

export const isUserSystemFilterOptionsFetched =
  (exchange: string, customer: string, tradingSystem: string, customerSystemId: string) =>
  (state: RootState): boolean =>
    exchange in selectFiltersState(state).userSystems &&
    customer in selectFiltersState(state).userSystems?.[exchange] &&
    tradingSystem in selectFiltersState(state).userSystems?.[exchange]?.[customer] &&
    customerSystemId in selectFiltersState(state).userSystems?.[exchange]?.[customer]?.[tradingSystem];
