import { sgwtConnect } from 'SgConnect';
import { HttpRequestMiddleware, HttpConfig } from 'utils/http/httpClient.models';

export const sgConnectHttpRequestMiddleware: HttpRequestMiddleware = (config: HttpConfig): HttpConfig => {
  if (!config.url.startsWith('/')) return config;
  return {
    ...config,
    headers: {
      ...(config.headers ?? {}),
      Authorization: sgwtConnect.getAuthorizationHeader(),
    },
  };
};
