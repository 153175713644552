import { createSelector } from 'reselect';
import { ExchangesState } from './exchanges.model';
import { Exchange } from 'models/Exchange';
import { ExchangeFeature, ExchangeInfo } from 'models/ExchangeInfo';
import { DcErrorResponse } from 'models/Responses/DcErrorResponse';
import { getCurrentTimeZoneType } from 'store/selectors';
import { RootState } from 'store/state';
import { isValidTimeZone } from 'utils/dates/dates';
import { isRequestStatusInProgress, isRequestStatusFail } from 'utils/requests/requestStatus';

const selectExchangesState = (state: RootState): ExchangesState => state.exchanges;
const selectExchangeDefinitions = (state: RootState): Exchange[] => state.exchanges.definitions ?? [];
const selectExchangesInfo = (state: RootState): ExchangeInfo[] => state.exchanges.infos ?? [];

// TODO: Unit test
export const getExchangeFilterOptions = createSelector(selectExchangeDefinitions, definitions =>
  definitions.map(e => ({ label: e.displayName, value: e.micCode })).sort((a, b) => a.label.localeCompare(b.label)),
);

export const getExchangeFilterOptionsNex = (withFeatures: ExchangeFeature[]) =>
  createSelector(selectExchangesInfo, info =>
    info
      .filter(({ features }) => withFeatures?.every(val => features?.includes(val)))
      .map(e => ({ label: e.displayName, value: e.micCode }))
      .sort((a, b) => a.label.localeCompare(b.label)),
  );

// TODO: Unit test
export const isExchangeDefinitionsFetchInProgress = (state: RootState): boolean =>
  isRequestStatusInProgress(selectExchangesState(state).definitionsFetchStatus);

// TODO: Unit test
export const isExchangeDefinitionsFetchFailed = (state: RootState): boolean =>
  isRequestStatusFail(selectExchangesState(state).definitionsFetchStatus);

// TODO: Unit test
export const getExchangeDefinitionsError = (state: RootState): DcErrorResponse | undefined =>
  selectExchangesState(state).definitionsFetchError;

// TODO: Unit test
export const isExchangesInfoFetchInProgress = (state: RootState): boolean =>
  isRequestStatusInProgress(selectExchangesState(state).infosFetchStatus);

// TODO: Unit test
export const isExchangesInfoFetchFailed = (state: RootState): boolean =>
  isRequestStatusFail(selectExchangesState(state).infosFetchStatus);

// TODO: Unit test
export const getExchangesInfoError = (state: RootState): DcErrorResponse | undefined =>
  selectExchangesState(state).infosFetchError;

export const isExchangesSupportedFeature =
  (feature: ExchangeFeature, exchange?: string) =>
  (state: RootState): boolean => {
    if (!exchange) return false;

    const exchangesInfo = selectExchangesInfo(state);
    return exchangesInfo.find(x => x.micCode === exchange)?.features?.includes(feature) ?? false;
  };

export const getExchangeInfo = (exchange?: string) => (state: RootState) =>
  selectExchangesInfo(state).find(info => info.micCode === exchange);

export const getExchangeTimeZone = (exchange?: string) => (state: RootState) => {
  if (!exchange) return undefined;

  const exchangeTimeZone = getExchangeInfo(exchange)(state)?.timeZone;
  return exchangeTimeZone && isValidTimeZone(exchangeTimeZone) ? exchangeTimeZone : undefined;
};

export const getExchangeTimeZoneOrDefault =
  (exchange?: string) =>
  (state: RootState): string => {
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (!exchange) return localTimeZone;

    const exchangeTimeZone = getExchangeInfo(exchange)(state)?.timeZone;
    return exchangeTimeZone && isValidTimeZone(exchangeTimeZone) ? exchangeTimeZone : localTimeZone;
  };

export const getAppliedTimeZone =
  (exchange?: string) =>
  (state: RootState): string => {
    const timeZoneType = getCurrentTimeZoneType(state);
    return timeZoneType === 'local'
      ? Intl.DateTimeFormat().resolvedOptions().timeZone
      : getExchangeTimeZoneOrDefault(exchange)(state);
  };
