import { QueryType } from 'models/Queries';
import { QueryFilters } from 'models/QueryFilters';
import { parseTime, toMarketClose, toMarketOpen } from 'utils/dates/dates';

export type QueriesState = Record<QueryType, QueryState>;

export interface QueryState {
  selectedFilters: QueryFilters;
  appliedFilters: QueryFilters;
  pageSize: number;
}

export const initialQueriesState: QueriesState = {
  orders: {
    appliedFilters: {},
    selectedFilters: {
      businessDates: {
        from: toMarketOpen(parseTime('06:00:00.000')),
        to: toMarketClose(parseTime('05:59:59.999')),
      },
    },
    pageSize: window.appConfigs.defaultPageSize,
  },
  executions: {
    appliedFilters: {},
    selectedFilters: {
      businessDates: {
        from: toMarketOpen(parseTime('06:00:00.000')),
        to: toMarketClose(parseTime('05:59:59.999')),
      },
    },
    pageSize: window.appConfigs.defaultPageSize,
  },
  positions: {
    appliedFilters: {},
    selectedFilters: {
      businessDates: {
        from: toMarketOpen(parseTime('06:00:00.000')),
        to: toMarketClose(parseTime('05:59:59.999')),
      },
    },
    pageSize: 0,
  },
};
