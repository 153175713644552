import queryString from 'query-string';
import { QueryFilters } from 'models/QueryFilters';
import { isDefinedAndNotEmpty } from 'utils/string/string';

const filterEmptyUndefinedValues = (str: string | undefined | null): string | undefined =>
  isDefinedAndNotEmpty(str) ? str : undefined;

// TODO:: Unit test
export function getPagedQueryString(page: number, size: number, filters: QueryFilters): string {
  const params = getPagedQueryParam(page, size, filters);
  return queryString.stringify(params);
}

export const getDataQueryString = (filters: QueryFilters): string => {
  const params = getDataQueryParam(filters);
  return queryString.stringify(params);
};

function getDataQueryParam(filters: QueryFilters) {
  return {
    account: filterEmptyUndefinedValues(filters.account),
    customer: filterEmptyUndefinedValues(filters.customer),
    endDate: filters.businessDates?.to?.toISOString(),
    exchangeOrderId: filterEmptyUndefinedValues(filters.exchangeOrderId),
    price: filters.price,
    product: filterEmptyUndefinedValues(filters.product),
    productDescription: filterEmptyUndefinedValues(filters.productDescription),
    quantity: filters.quantity,
    side: filters.side?.length === 1 ? filters.side[0] : undefined,
    startDate: filters.businessDates?.from?.toISOString(),
    functionalStatus: filters.functionalStatus ?? undefined,
    tradingSystem: filterEmptyUndefinedValues(filters.tradingSystem),
    customerSystemId: filterEmptyUndefinedValues(filters.customerSystemId),
    userSystemId: filterEmptyUndefinedValues(filters.userSystemId),
    timeInForce: filters.timeInForce ?? undefined,
  };
}

function getPagedQueryParam(page: number, size: number, filters: QueryFilters) {
  return {
    ...getDataQueryParam(filters),
    page,
    size,
  };
}
