import { ExchangeInfo } from 'models/ExchangeInfo';
import { OrderFunctionalStatus } from 'models/Orders/OrderFunctionalStatus';
import { OrderSide } from 'models/Orders/OrderSide';
import { OrderTimeInForce } from 'models/Orders/TimeInForce';
import { QueryType } from 'models/Queries';
import { BusinessDateRange, FilterType } from 'models/QueryFilters';

export type QueriesActions = QuerySelectedFilterActions | QueryAppliedFiltersCleared | QueryPageSizeChanged;

export type QuerySelectedFilterActions =
  | QueryAccountUpdated
  | QueryBusinessDatesUpdated
  | QueryCustomerUpdated
  | QueryExchangeUpdated
  | QueryExchangeOrderIdUpdated
  | QueryPriceUpdated
  | QueryProductUpdated
  | QueryProductDescriptionUpdated
  | QueryQuantityUpdated
  | QuerySideUpdated
  | QueryFunctionalStatusUpdated
  | QueryTradingSystemUpdated
  | QueryCustomerSystemUpdated
  | QueryUserSystemUpdated
  | QueryTimeInForceUpdated;
export const QUERY_ACCOUNT_UPDATED = '[Queries] Account updated';
export const queryAccountUpdated = (queryType: QueryType, filterType: FilterType, value?: string) =>
  ({
    type: QUERY_ACCOUNT_UPDATED,
    queryType,
    filterType,
    value,
  } as const);

export type QueryAccountUpdated = ReturnType<typeof queryAccountUpdated>;

export const QUERY_BUSINESS_DATE_RANGE_UPDATED = '[Queries] Business date range updated';
export const queryBusinessDateRangeUpdated = (
  queryType: QueryType,
  filterType: FilterType,
  value?: BusinessDateRange,
) =>
  ({
    type: QUERY_BUSINESS_DATE_RANGE_UPDATED,
    queryType,
    filterType,
    value,
  } as const);
export type QueryBusinessDatesUpdated = ReturnType<typeof queryBusinessDateRangeUpdated>;

export const QUERY_CUSTOMER_UPDATED = '[Queries] Customer updated';
export const queryCustomerUpdated = (queryType: QueryType, filterType: FilterType, value?: string) =>
  ({
    type: QUERY_CUSTOMER_UPDATED,
    queryType,
    filterType,
    value,
  } as const);
export type QueryCustomerUpdated = ReturnType<typeof queryCustomerUpdated>;

export const QUERY_EXCHANGE_UPDATED = '[Queries] Exchange updated';
export const queryExchangeUpdated = (
  queryType: QueryType,
  filterType: FilterType,
  value?: string,
  exchangeInfo?: ExchangeInfo,
) =>
  ({
    type: QUERY_EXCHANGE_UPDATED,
    queryType,
    filterType,
    value,
    exchangeInfo,
  } as const);
export type QueryExchangeUpdated = ReturnType<typeof queryExchangeUpdated>;

export const QUERY_EXCHANGE_ORDER_ID_UPDATED = '[Queries] Exchange order id updated';
export const queryExchangeOrderIdUpdated = (queryType: QueryType, filterType: FilterType, value?: string) =>
  ({
    type: QUERY_EXCHANGE_ORDER_ID_UPDATED,
    queryType,
    filterType,
    value,
  } as const);
export type QueryExchangeOrderIdUpdated = ReturnType<typeof queryExchangeOrderIdUpdated>;

export const QUERY_PRICE_UPDATED = '[Queries] Price updated';
export const queryPriceUpdated = (queryType: QueryType, filterType: FilterType, value?: number) =>
  ({
    type: QUERY_PRICE_UPDATED,
    queryType,
    filterType,
    value,
  } as const);
export type QueryPriceUpdated = ReturnType<typeof queryPriceUpdated>;

export const QUERY_PRODUCT_UPDATED = '[Queries] Product updated';
export const queryProductUpdated = (queryType: QueryType, filterType: FilterType, value?: string) =>
  ({
    type: QUERY_PRODUCT_UPDATED,
    queryType,
    filterType,
    value,
  } as const);
export type QueryProductUpdated = ReturnType<typeof queryProductUpdated>;

export const QUERY_PRODUCT_DESCRIPTION_UPDATED = '[Queries] Product description updated';
export const queryProductDescriptionUpdated = (queryType: QueryType, filterType: FilterType, value?: string) =>
  ({
    type: QUERY_PRODUCT_DESCRIPTION_UPDATED,
    queryType,
    filterType,
    value,
  } as const);
export type QueryProductDescriptionUpdated = ReturnType<typeof queryProductDescriptionUpdated>;

export const QUERY_QUANTITY_UPDATED = '[Queries] Quantity updated';
export const queryQuantityUpdated = (queryType: QueryType, filterType: FilterType, value?: number) =>
  ({
    type: QUERY_QUANTITY_UPDATED,
    queryType,
    filterType,
    value,
  } as const);
export type QueryQuantityUpdated = ReturnType<typeof queryQuantityUpdated>;

export const QUERY_SIDE_UPDATED = '[Queries] Side updated';
export const querySideUpdated = (queryType: QueryType, filterType: FilterType, value?: OrderSide[]) =>
  ({ type: QUERY_SIDE_UPDATED, queryType, filterType, value } as const);
export type QuerySideUpdated = ReturnType<typeof querySideUpdated>;

export const QUERY_FUNCTIONAL_STATUS_UPDATED = '[Queries] Functional status updated';
export const queryFunctionalStatusUpdated = (
  queryType: QueryType,
  filterType: FilterType,
  value?: OrderFunctionalStatus[],
) =>
  ({
    type: QUERY_FUNCTIONAL_STATUS_UPDATED,
    queryType,
    filterType,
    value,
  } as const);
export type QueryFunctionalStatusUpdated = ReturnType<typeof queryFunctionalStatusUpdated>;

export const QUERY_TRADING_SYSTEM_UPDATED = '[Queries] Trading system updated';
export const queryTradingSystemUpdated = (queryType: QueryType, filterType: FilterType, value?: string) =>
  ({
    type: QUERY_TRADING_SYSTEM_UPDATED,
    queryType,
    filterType,
    value,
  } as const);
export type QueryTradingSystemUpdated = ReturnType<typeof queryTradingSystemUpdated>;

export const QUERY_CUSTOMER_SYSTEM_UPDATED = '[Queries] Customer system updated';
export const queryCustomerSystemUpdated = (queryType: QueryType, filterType: FilterType, value?: string) =>
  ({
    type: QUERY_CUSTOMER_SYSTEM_UPDATED,
    queryType,
    filterType,
    value,
  } as const);
export type QueryCustomerSystemUpdated = ReturnType<typeof queryCustomerSystemUpdated>;

export const QUERY_USER_SYSTEM_UPDATED = '[Queries] User system updated';
export const queryUserSystemUpdated = (queryType: QueryType, filterType: FilterType, value?: string) =>
  ({
    type: QUERY_USER_SYSTEM_UPDATED,
    queryType,
    filterType,
    value,
  } as const);
export type QueryUserSystemUpdated = ReturnType<typeof queryUserSystemUpdated>;

export const QUERY_TIME_IN_FORCE_UPDATED = '[Queries] Time in Force updated';
export const queryTimeInForceUpdated = (queryType: QueryType, filterType: FilterType, value?: OrderTimeInForce) =>
  ({
    type: QUERY_TIME_IN_FORCE_UPDATED,
    queryType,
    filterType,
    value,
  } as const);
export type QueryTimeInForceUpdated = ReturnType<typeof queryTimeInForceUpdated>;

export const QUERY_APPLIED_FILTERS_CLEARED = '[Queries] Applied filters cleared';
export const queryAppliedFiltersCleared = (queryType: QueryType) =>
  ({ type: QUERY_APPLIED_FILTERS_CLEARED, queryType } as const);
export type QueryAppliedFiltersCleared = ReturnType<typeof queryAppliedFiltersCleared>;

export const QUERY_PAGE_SIZE_CHANGED = '[Queries] Page size changed';
export const queryPageSizeChanged = (queryType: QueryType, size: number) =>
  ({ type: QUERY_PAGE_SIZE_CHANGED, queryType, size } as const);
export type QueryPageSizeChanged = ReturnType<typeof queryPageSizeChanged>;
